'use strict';

angular.module('windmanagerApp')
  .directive('tabset', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        $(element).tabset(scope.$eval(attrs.tabset));
      }
    };
  });
